<template>

<v-stepper v-model="step" class="col-12">
  <v-stepper-header>
    <v-stepper-step
      :complete="step > 1"
      color="#ac4a0e"
      step="1"
    >
      Productos
    </v-stepper-step>

    <v-divider></v-divider>

    <v-stepper-step
      :complete="step > 2"
      color="#ac4a0e"
      step="2"
    >
      Cuenta
    </v-stepper-step>

    <v-divider></v-divider>

    <v-stepper-step
      :complete="step > 3"
      color="#ac4a0e"
      step="3"
    >
      Pago
    </v-stepper-step>

    <v-divider></v-divider>

    <v-stepper-step
      :complete="step > 4"
      color="#ac4a0e"
      step="4"
    >
      Confirmación
    </v-stepper-step>
  </v-stepper-header>

  <v-stepper-items>
    <v-stepper-content step="1" class="px-0">
      <v-container fluid class="pa-0">
        <template v-if="isLoadingGrupos">
          <div class="primary--text mb-4">
            <v-progress-circular
              indeterminate
              color="primary"
              class="mr-2"
            ></v-progress-circular>

            Cargando los datos, por favor espere...
          </div>
        </template>
        <template v-else-if="grupos.length === 0">
          <v-alert type="info" class="font-weight-bold">Disculpe, en este momento no tenemos cupones disponibles. Puede acercarse al Parque de Agua para sacar los tickets en el día.</v-alert>
        </template>
        <template v-else>
          <div v-for="(grupo, grupoIndex) in grupos" :key="grupoIndex">
            <div class="h6 ml-4 mb-4">{{ grupo.nombre }}</div>

            <v-row justify="center" class="mb-4">
              <v-expansion-panels accordion multiple>
                <v-expansion-panel
                  v-for="(producto, productoIndex) in grupo.productos"
                  :key="productoIndex"
                >
                  <v-expansion-panel-header v-slot="{ open }">
                    <v-row align="center">
                      <v-col cols="6" class="d-flex flex-row align-center">
                        <v-avatar class="mr-2">
                          <img
                            :src="imgBaseURL + producto.imagen"
                            alt=""
                          >
                        </v-avatar>
                        <span>
                          {{ producto.nombre }}
                        </span>
                      </v-col>
                      <v-col
                        cols="6"
                        class="text--secondary"
                        fill-height
                      >
                        <v-fade-transition leave-absolute>
                          <span v-if="open">Cupones</span>
                          <v-row
                            v-else-if="producto.cant_cupones || 0"
                            no-gutters
                            style="width: 100%"
                          >
                            <v-col cols="6">
                              Cupones: {{ producto.cant_cupones || 0 }}
                            </v-col>
                            <v-col cols="6">
                              Subtotal: <span class="text-no-wrap">$ {{ producto.subtotal_fmt || 0 }}</span>
                            </v-col>
                          </v-row>
                        </v-fade-transition>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-data-table
                      :headers="headersCupones"
                      :items="producto.cupones"
                      :loading="loading"
                      class="mb-4"
                      disable-filtering
                      disable-pagination
                      disable-sort
                      hide-default-footer
                    >
                      <template v-slot:item.cantidad="{ item }">
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="item.cantidad"
                            type="number"
                            step="1"
                            min="0"
                            dense
                            outlined
                            hide-details
                            @input="onInputCupon(grupoIndex, productoIndex, item)"
                            style="min-width: 70px;"
                          ></v-text-field>
                        </v-col>
                      </template>
                      <template v-slot:item.subtotal="{ item }">
                        $ {{ item.subtotal_fmt }}
                      </template>
                      <template v-slot:item.action="{ item }">
                        <v-btn
                          @click="limpiarCupon(grupoIndex, productoIndex, item)"
                          small
                          tile
                        >
                          Limpiar
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </div>
        </template>

        <v-dialog v-model="detalleDialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Cupones agregados</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <template v-for="cupon in selectedCupones">
                    <v-row :key="cupon.id" justify="center">
                      <v-col cols="10">
                        {{ cupon.producto_nombre }} - Vigencia del {{ cupon.vigencia_desde_fmt }} al {{ cupon.vigencia_hasta_fmt }} x {{ cupon.cantidad }}
                      </v-col>

                      <v-col cols="2">
                        <span class="text-no-wrap">$ {{ cupon.subtotal_fmt }}</span>
                      </v-col>
                    </v-row>
                  </template>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="detalleDialog = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card flat>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="getDataStepUser"
              :disabled="!selectedCupones.length"
              :loading="isLoadingBtnStep"
            >
              Continuar
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              text
              @click="detalleDialog = true"
              :disabled="!selectedCupones.length"
            >
              TOTAL = $ {{ total_fmt }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-stepper-content>

    <v-stepper-content step="2" class="px-0">
      <v-container fluid>
        <template>
          <v-row v-if="user" justify="center" class="mb-4">
            <v-col>
              <div class="mb-2">Usted abrió sesión como: <b>{{ user.username }}</b></div>

              <div>No es mi cuenta, <a text color="primary" @click="sessionLogout">abrir sesión con otra</a></div>
            </v-col>
          </v-row>

          <v-row v-else justify="center" class="mb-4">
            <v-col cols="12" sm="6">
              <v-card>
                <v-card-title>
                  <span class="headline">Registrar Cuenta</span>
                </v-card-title>

                <v-card-text>
                  <v-form ref="registerForm" data-vv-scope="register">
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 sm12>
                          <v-text-field
                          v-model="datos_registro.registro_email"
                          label="E-Mail"
                          outlined
                          v-validate="'required|email'"
                          data-vv-name="email"
                          data-vv-as="E-Mail"
                          :error-messages="errors.collect('register.email')"
                          required
                          autocomplete="off"
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12>
                          <v-text-field
                          v-model="datos_registro.registro_password"
                          type="password"
                          label="Contraseña"
                          ref="password"
                          outlined
                          v-validate="'required'"
                          data-vv-name="password"
                          data-vv-as="Contraseña"
                          :error-messages="errors.collect('register.password')"
                          required
                          autocomplete="off"
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12>
                          <v-text-field
                          v-model="datos_registro.registro_confirmar_password"
                          type="password"
                          label="Repita Contraseña"
                          outlined
                          v-validate="'required|confirmed:password'"
                          data-vv-name="confirm_password"
                          data-vv-as="Repita Contraseña"
                          :error-messages="errors.collect('register.confirm_password')"
                          required
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="6">
              <v-card>
                <v-card-title>
                  <span class="headline">Iniciar Sesión</span>
                </v-card-title>

                <v-card-text>
                  <v-form ref="loginForm" data-vv-scope="login">
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 sm12>
                          <v-text-field
                          v-model="log_username"
                          label="Nombre de Usuario"
                          outlined
                          v-validate="'required'"
                          data-vv-name="username"
                          data-vv-as="Nombre de Usuario"
                          :error-messages="errors.collect('login.username')"
                          required
                          autocomplete="off"
                          @keyup.enter="sessionLogin"
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12>
                          <v-text-field
                          v-model="log_password"
                          type="password"
                          label="Contraseña"
                          outlined
                          v-validate="'required'"
                          data-vv-name="password"
                          data-vv-as="Contraseña"
                          :error-messages="errors.collect('login.password')"
                          required
                          autocomplete="off"
                          @keyup.enter="sessionLogin"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-container>
                    <v-layout row wrap text-center>
                      <v-flex xs12 sm12 md6 lg6>
                        <v-btn color="blue darken-1" text @click="sessionLogin">Iniciar Sesión</v-btn>
                      </v-flex>

                      <v-spacer></v-spacer>

                      <v-flex xs12 sm12 md6 lg6>
                        <v-dialog
                        v-model="recoverUserDialog"
                        persistent
                        max-width="600px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                          color="grey darken-1"
                          text
                          v-bind="attrs"
                          v-on="on"
                          >
                          Olvidé mi contraseña
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Recuperar Contraseña</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-form
                            ref="recoverUserForm"
                            data-vv-scope="recover"
                            >
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                ref="recoverEmail"
                                v-model="recoverEmail"
                                label="Dirección de E-mail"
                                outlined
                                v-validate="'required|email'"
                                data-vv-name="email"
                                data-vv-as="Dirección de E-mail"
                                :error-messages="errors.collect('recover.email')"
                                required
                                autocomplete="off"
                                @keyup.enter="recoverUser"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                @click="recoverUserDialog = false"
                                >
                                Cerrar
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="recoverUser"
                              >
                                Recuperar contraseña
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <v-btn
          color="primary"
          @click="getDataStepPago"
          :loading="isLoadingBtnStep"
        >
          Continuar
        </v-btn>

        <v-btn
          text
          @click="step = 1"
        >
          Volver
        </v-btn>
      </v-container>
    </v-stepper-content>

    <v-stepper-content step="3" class="px-0">
      <v-form ref="pagoForm" data-vv-scope="pago">
        <v-container fluid>
          <template>
            <v-row justify="center" class="mb-4">
              <v-col>
                <v-card>
                  <v-card-text>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 sm12 md5>
                          <v-select
                            v-model="datosPago.metodo_pago"
                            :items="metodosPago"
                            label="Método de Pago"
                            ref="metodo_pago_id"
                            dense
                            outlined
                            item-text="nombre"
                            item-value="id"
                            return-object
                            autocomplete="off"
                            v-validate="'required'"
                            data-vv-name="paymentMethod"
                            data-vv-as="Método de Pago"
                            :error-messages="errors.collect('pago.paymentMethod')"
                            :hint="datosPago.metodo_pago ? datosPago.metodo_pago.instrucciones : ''"
                            :loading="isLoadingMetodoPago"
                            required
                          ></v-select>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row justify="center" class="mb-4">
              <v-col>
                <v-card>
                  <v-card-title>
                    <span class="headline text-break">Datos de Facturación</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 sm12 md3>
                          <v-select
                            ref="FactTipoCbteInput"
                            v-model="datosPago.FactTipoCbteInput"
                            :items="tiposComprobante"
                            dense
                            outlined
                            label="Tipo de Comprobante"
                            item-text="descripcion"
                            item-value="codigo"
                            autocomplete="off"
                            v-validate="'required'"
                            data-vv-name="FactTipoCbteInput"
                            data-vv-as="Tipo de Comprobante"
                            :error-messages="errors.collect('pago.FactTipoCbteInput')"
                            :loading="isLoadingTiposComprobante || isLoadingDatosFacturacion"
                            required
                            @change="onTipoComprobanteChange(datosPago)"
                          ></v-select>
                        </v-flex>
                        <v-flex xs12 sm12 md3>
                          <v-select
                            v-model="datosPago.FactDocTipoInput"
                            :items="tiposDocumento"
                            label="Tipo Documento"
                            ref="FactDocTipoInput"
                            dense
                            outlined
                            item-text="descripcion"
                            item-value="id"
                            autocomplete="off"
                            v-validate="'required|doc_tipo:FactTipoCbteInput'"
                            data-vv-name="FactDocTipoInput"
                            data-vv-as="Tipo Documento"
                            :error-messages="errors.collect('pago.FactDocTipoInput')"
                            required
                            :loading="isLoadingTipoDoc || isLoadingDatosFacturacion"
                          ></v-select>
                        </v-flex>

                        <v-flex xs12 sm12 md3>
                          <v-text-field
                            v-model="datosPago.FactDocNroInput"
                            type="number"
                            label="Nº Documento"
                            dense
                            outlined
                            autocomplete="off"
                            v-validate="'required|integer|min_value:0'"
                            data-vv-name="FactDocNroInput"
                            data-vv-as="Nº Documento"
                            :error-messages="errors.collect('pago.FactDocNroInput')"
                            required
                            min="0"
                            step="1"
                            :loading="isLoadingDatosFacturacion"
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md3>
                          <v-select
                            v-model="datosPago.FactCategoriaIvaInput"
                            :items="categoriasIVA"
                            label="Categoría IVA"
                            ref="FactCategoriaIvaInput"
                            dense
                            outlined
                            item-text="descripcion"
                            item-value="id"
                            autocomplete="off"
                            v-validate="'required'"
                            data-vv-name="FactCategoriaIvaInput"
                            data-vv-as="Categoría IVA"
                            :error-messages="errors.collect('pago.FactCategoriaIvaInput')"
                            required
                            :loading="isLoadingCategIVA || isLoadingDatosFacturacion"
                          ></v-select>
                        </v-flex>
                      </v-layout>
                    </v-container>

                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 sm12 md4>
                          <v-text-field
                            v-model="datosPago.FactRazonSocialInput"
                            type="text"
                            label="Razón Social / Nombre y Apellido"
                            dense
                            outlined
                            autocomplete="off"
                            v-validate="'required'"
                            data-vv-name="FactRazonSocialInput"
                            data-vv-as="Razón Social / Nombre y Apellido"
                            :error-messages="errors.collect('pago.FactRazonSocialInput')"
                            required
                            :loading="isLoadingDatosFacturacion"
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md4>
                          <v-text-field
                            v-model="datosPago.FactEmailInput"
                            type="email"
                            label="E-Mail"
                            dense
                            outlined
                            autocomplete="off"
                            v-validate="'required|email'"
                            data-vv-name="FactEmailInput"
                            data-vv-as="E-Mail"
                            :error-messages="errors.collect('pago.FactEmailInput')"
                            required
                            :loading="isLoadingDatosFacturacion"
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md4>
                          <v-text-field
                            v-model="datosPago.FactTelefonoInput"
                            type="text"
                            label="Teléfono"
                            dense
                            outlined
                            autocomplete="off"
                            v-validate="'required'"
                            data-vv-name="FactTelefonoInput"
                            data-vv-as="Teléfono"
                            :error-messages="errors.collect('pago.FactTelefonoInput')"
                            required
                            :loading="isLoadingDatosFacturacion"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <v-btn
            color="primary"
            @click="validarDatosDePago"
            :loading="isLoadingBtnStep"
          >
            Continuar
          </v-btn>

          <v-btn
            text
            @click="step = 2"
          >
            Volver
          </v-btn>
        </v-container>
      </v-form>
    </v-stepper-content>

    <v-stepper-content step="4" class="px-0">
      <v-container fluid>
        <v-row justify="center" class="mb-4">
          <v-col>
            <h4>Facturación</h4>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr class="text-no-wrap">
                    <th>Método de Pago</th>
                    <th>Documento</th>
                    <th>Categoría IVA</th>
                    <th>Razón Social / Nombre y Apellido</th>
                    <th>E-Mail</th>
                    <th>Teléfono</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ datosPago.metodo_pago ? datosPago.metodo_pago.nombre : '' }}</td>
                    <td>{{ datosPago.FactDocTipoText }} {{ datosPago.FactDocNroInput }}</td>
                    <td>{{ datosPago.FactCategoriaIvaText }}</td>
                    <td>{{ datosPago.FactRazonSocialInput }}</td>
                    <td>{{ datosPago.FactEmailInput }}</td>
                    <td>{{ datosPago.FactTelefonoInput }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row justify="center" class="mb-4">
          <v-col>
            <h4>Cupones</h4>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr class="text-no-wrap">
                    <th class="text-left">Cupón</th>
                    <th class="text-left">Descripción</th>
                    <th class="text-left">Vigencia Desde</th>
                    <th class="text-left">Vigencia Hasta</th>
                    <th class="text-right">Precio Unitario</th>
                    <th class="text-center">Cantidad</th>
                    <th class="text-right">Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(cupon, k) in selectedCupones">
                    <tr :key="k">
                      <td>{{ cupon.producto_nombre }}</td>
                      <td>{{ cupon.descripcion }}</td>
                      <td>{{ cupon.vigencia_desde_fmt }}</td>
                      <td>{{ cupon.vigencia_hasta_fmt }}</td>
                      <td class="text-right">{{ cupon.importe_fmt }}</td>
                      <td class="text-center">{{ cupon.cantidad }}</td>
                      <td class="text-right text-no-wrap text-monospace">$ {{ cupon.subtotal_fmt }}</td>
                    </tr>
                  </template>
                  <tr>
                    <td colspan="6" class="text-right"><strong>TOTAL: </strong></td>
                    <td class="text-right text-monospace">$ {{ total_fmt }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-btn
          color="primary"
          @click="confirmar"
          :loading="isLoadingBtnStep"
        >
          Confirmar
        </v-btn>

        <v-btn
          text
          @click="step = 3"
        >
          Volver
        </v-btn>
      </v-container>
    </v-stepper-content>
  </v-stepper-items>


  <v-expansion-panels accordion>
    <v-expansion-panel>
      <v-expansion-panel-header>
        VER CONDICIONES DE COMPRA ONLINE TICKETS PARQUE
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        1- Es <span class="font-weight-bold">obligatorio</span> para poder acceder al Parque de Agua o Parque Aéreo presentar el QR de forma impresa o en su celular en la boletería del Complejo. En su defecto comprobante de pago y DNI.<br>
        2- Complejo Americano no se hace responsable de las entradas que no hayan sido adquiridas en los puntos de venta autorizados.<br>
        3- <span class="font-weight-bold">No se admitirán devoluciones o cambios de entradas.</span><br>
        4- Una vez ingresado al Parque de Agua o Parque Aéreo el portador de la entrada no tendrá derecho alguno de reclamo si las condiciones climáticas cambiaran. El Complejo Americano no cuenta con seguro de lluvia.<br>
        5- El Complejo Americano se reserva el derecho de admisión y permanencia.<br>
        6- Las entradas adquiridas de forma online no podrán ser utilizadas para la reventa. <span class="font-weight-bold">Son intransferibles.</span><br>
        7- Las entradas tienen fecha de caducidad.<br>
        28 de Febrero de 2022 temporada alta. 31 de marzo 2022 temporada baja.
        8- Las entradas de menores (0 a 9 años) deberán presentar DNI en ventanilla de boletería.<br>
        9- Los tickets online se canjean por orden de llegada en las ventanillas de boletería del Complejo Americano.
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header>
        VER CONDICIONES DE COMPRA ONLINE TICKETS FESTIVAL MONTE BAILA
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        1- Es <span class="font-weight-bold">obligatorio</span> para poder acceder al Festival Monte Baila presentar el DNI y QR del ticket de forma impresa o en su celular en la boletería del Complejo. En su defecto comprobante de pago y DNI.<br>

        2- Complejo Americano no se hace responsable de las entradas o tickets que no hayan sido adquiridas en los puntos de venta autorizados.<br>

        3- <span class="font-weight-bold">No se admitirán devoluciones o cambios de entradas.</span><br>

        4- Una vez ingresado al Festival Monte Baila el portador de la entrada no tendrá derecho alguno de reclamo si las condiciones climáticas cambiaran. El Complejo Americano no cuenta con seguro de lluvia.<br>

        5- El Complejo Americano se reserva el derecho de admisión y permanencia.<br>

        6- Las entradas adquiridas de forma online no podrán ser utilizadas para la reventa. <span class="font-weight-bold">Son intransferibles y de uso personal.</span><br>

        7- Las entradas tienen fecha de caducidad.<br>

        8- El evento es para mayores de 18 años. <span class="font-weight-bold">No se puede ingresar con:</span> bebidas alcohólicas y/o energéticas, alimentos ajenos al evento, cualquier tipo de droga o sustancia ilegal, sustancias inflamables, armas de cualquier tipo, incluyendo fuegos artificiales, explosivos de cualquier tipo o elementos punzantes. Cualquier objeto/elemento que el personal de Complejo Americano crea que es ofensivo, dudoso o peligroso. Los mismos serán retenidos.<br>

        9- Los tickets online se canjean por orden de llegada en las ventanillas de boletería del Complejo Americano.
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</v-stepper>

</template>

<script>

  export default {
    data: () => ({
      imgBaseURL: (process.env.VUE_APP_IMG_BASE_URL && (location.hostname !== '192.168.10.200'))
        ? process.env.VUE_APP_IMG_BASE_URL
        : location.origin + '/img.php?path=',
      step: 1,
      loading: false,
      expanded: [],
      detalleDialog: false,
      headersCupones: [
        { text: 'Descripción', value: 'descripcion', cellClass: 'text-no-wrap' },
        { text: 'Vigencia Desde', value: 'vigencia_desde_fmt', cellClass: 'text-no-wrap' },
        { text: 'Vigencia Hasta', value: 'vigencia_hasta_fmt', cellClass: 'text-no-wrap' },
        { text: 'Precio Unitario', align: 'right', value: 'importe_fmt', cellClass: 'text-no-wrap' },
        { text: 'Cantidad', value: 'cantidad' },
        { text: 'Subtotal', align: 'right', value: 'subtotal', cellClass: 'text-no-wrap' },
        { text: '', value: 'action' }
      ],
      isLoadingGrupos: true,
      grupos: [],
      selectedCupones: [],
      total: 0,
      total_fmt: 0,
      user: false,
      datos_registro: {
        registro_email:'',
        registro_confirmar_password: '',
        registro_password: '',
      },
      datosPago: {
        metodo_pago: null,
        FactTipoCbteInput: '006',
        FactRazonSocialInput: '',
        FactDocTipoInput: '96',
        FactDocTipoText: '',
        FactDocNroInput: null,
        FactCategoriaIvaInput: '5',
        FactCategoriaIvaText: '',
        FactEmailInput: '',
        FactTelefonoInput: null,
      },
      log_username: '',
      log_password: '',
      metodosPago: [],
      tiposComprobante: [],
      categoriasIVA: [],
      tiposDocumento: [],
      resumenCupones: [],
      recoverUserDialog: false,
      recoverEmail: '',
      isLoadingMetodoPago: false,
      isLoadingTiposComprobante: false,
      isLoadingTipoDoc: false,
      isLoadingCategIVA: false,
      isLoadingDatosFacturacion: false,
      isLoadingBtnStep: false
    }),

    computed: {
      loggedIn () {
        return this.$store.state.loggedIn
      }
    },

    watch: {
      recoverUserDialog (val) {
        if (!val) {
          this.recoverEmail = ''
          this.$refs.recoverEmail.reset()
          this.$refs.recoverUserForm.reset()
          this.$validator.reset()
        }
      },

      loggedIn (val) {
        if (!val) {
          this.user = false
          this.resetStepUsers()

          if (this.step > 2) {
            this.step = 2
          }
        }
      },
    },

    methods: {
      query: function () {
        this.loading = true;

        this.$http.get('grupos')
        .then((response) => {
          var grupos = response.data

          // Agregar datos por defecto de los cupones
          for (var g = grupos.length -1; g >= 0; g--) {
            for (var i = grupos[g].productos.length - 1; i >= 0; i--) {
              Object.keys(grupos[g].productos[i].cupones).forEach((key) => {
                grupos[g].productos[i].cupones[key].producto_nombre = grupos[g].productos[i].nombre;
                grupos[g].productos[i].cupones[key].cantidad = 0;
                grupos[g].productos[i].cupones[key].subtotal = 0;
                grupos[g].productos[i].cupones[key].subtotal_fmt = 0;
              });
            }
          }

          this.grupos = grupos
        })
          .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.loading = false;
          this.isLoadingGrupos = false;
        })
      },

      formatNumber (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },

      onInputCupon: function (grupoIndex, productIndex, item) {
        var index = this.selectedCupones.findIndex((element) => {
          return (element.id == item.id)
        })
        var cant = parseInt(item.cantidad)

      // Acualizar subtotal
      item.subtotal = cant * parseFloat(item.importe)
      item.subtotal_fmt = this.formatNumber( item.subtotal )

      // Actualizar cupones seleccionados
      if (cant > 0) {
        // Agregar o modificar
        if (index > -1) {
          this.selectedCupones[index] = item
        } else {
          this.selectedCupones.push(item)
        }
      } else {
        // Quitar
        if (index > -1) {
          this.selectedCupones.splice(index, 1)
        }
      }

      // Ordenar por producto y fechas de vigencia
      this.selectedCupones.sort(function(a, b) {
        var textA = a.producto_nombre + a.vigencia_desde + a.vigencia_hasta
        var textB = b.producto_nombre + b.vigencia_desde + b.vigencia_hasta

        if (textA > textB) {
          return 1;
        }

        if (textA < textB) {
          return -1;
        }

        // a must be equal to b
        return 0;
      })

      this.updateProductoSubTotal(grupoIndex, productIndex)
      this.calcularTotal()
    },

    updateProductoSubTotal: function (gindex, index) {
      var cupones = this.grupos[gindex].productos[index].cupones
      var subtotal = 0
      var cant_cupones = 0

      for (var i = cupones.length - 1; i >= 0; i--) {
        subtotal+= cupones[i].subtotal
        cant_cupones+= parseInt(cupones[i].cantidad)
      }

      this.grupos[gindex].productos[index].cant_cupones = cant_cupones
      this.grupos[gindex].productos[index].subtotal = subtotal
      this.grupos[gindex].productos[index].subtotal_fmt = this.formatNumber( subtotal )
    },

    calcularTotal: function () {
      let totalGeneral = 0

      Object.keys(this.selectedCupones).forEach((key) => {
        totalGeneral+= parseFloat(this.selectedCupones[key].subtotal)
      });

      this.total = totalGeneral
      this.total_fmt = this.formatNumber( totalGeneral )
    },

    limpiarCupon: function (grupoIndex, productIndex, item) {
      var index = this.selectedCupones.findIndex((element) => {
        return (element.id == item.id)
      })

      this.selectedCupones.splice(index, 1)

      item.cantidad = 0
      item.subtotal = 0
      item.subtotal_fmt = 0

      this.updateProductoSubTotal(grupoIndex, productIndex)
      this.calcularTotal()
    },

    getDataStepUser: function () {
      this.isLoadingBtnStep = true
      this.resetStepUsers()

      this.$http.get('usuario')
      .then((response) => {
        this.user = response.data
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        this.isLoadingBtnStep = false
        this.step = 2
      })
    },

    sessionLogout: function () {
      sessionStorage.removeItem('cupones-token')
      this.$store.state.loggedIn = false
    },

    resetStepUsers: function () {
      if (this.user !== false) {
        this.$refs.loginForm?.reset()
        this.$refs.registerForm?.reset()
        this.$validator.reset()
      }
    },

    sessionLogin: function () {
      let credentials = {
        username: this.log_username,
        password: this.log_password
      };

      this.$validator.validateAll('login')
      .then(result => {
        if (!result) return;

        this.$http.post('login_check', credentials, { skipHijack: true }, 'json')
        .then((response) => {
          if (response.data) {
            sessionStorage.setItem('cupones-token', response.data.token)
            this.$store.state.loggedIn = true
            this.getDataStepUser()
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 400:
              this.$eventHub.$emit('snackbar-message', 'Credenciales no válidas')

              Object.keys(error.response.data).forEach(key => {
                let value = error.response.data[key]

                this.errors.add({
                  field: key,
                  msg: value
                });
              });

              break;
            case 401:
              this.$eventHub.$emit('snackbar-message', error.response.data.message || 'Error')
              break;
            default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud')
          }
        })
        .then(() => {
          this.loading = false;
        })
      });
    },

    recoverUser: function () {
      this.$validator.validateAll('recover').then(result => {
        if (!result) return;

        var credentials = {
          email: this.recoverEmail,
        };

        this.$http.post('json-recuperar-credenciales', credentials, null, 'json')
        .then((response) => {
          this.$eventHub.$emit('snackbar-message', response.data.message)
          this.recoverUserDialog = false
        })
        .catch((error) => {
          switch (error.response.status) {
            case 400:
              this.$eventHub.$emit('snackbar-message', 'Los datos no son válidos', 'error')

              break;
            case 401:
              break;
            case 422:
              this.$eventHub.$emit('snackbar-message', error.response.data?.message || 'Disculpe, no se pudo procesar su solicitud', 'error')

            break;
            default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
          }
        })
        .then(() => {
          this.loading = false;
        })
      });
    },

    ultimaFacturacion: function () {
      this.isLoadingDatosFacturacion = true

      return this.$http.get('ultima_facturacion')
      .catch((error) => {
          console.log(error);
        })
      .finally(() => {
        this.isLoadingDatosFacturacion = false
      })
    },

    listTiposComprobante: function () {
      this.isLoadingTiposComprobante = true;

      this.$http.get('tipos-comprobante')
      .then((response) => {
        this.tiposComprobante = response.data
      })
      .catch((error) => {
        console.log(error);
        this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
      })
      .then(() => {
        this.isLoadingTiposComprobante = false;
      })
    },

    listCategoriasIVA: function () {
      this.isLoadingCategIVA = true

      this.$http.get('categorias-iva')
      .then((response) => {
        this.categoriasIVA = response.data
      })
      .catch((error) => {
          console.log(error);
        })
      .then(() => {
        this.isLoadingCategIVA = false
      })
    },

    listMetodosPago: function () {
      this.isLoadingMetodoPago = true

      this.$http.get('metodos-pago')
      .then((response) => {
        this.metodosPago = response.data
      })
      .catch((error) => {
          console.log(error);
        })
      .then(() => {
        this.isLoadingMetodoPago = false
      })
    },

    listTiposDocumento: function () {
      this.isLoadingTipoDoc = true

      this.$http.get('tipos-documento')
      .then((response) => {
        this.tiposDocumento = response.data
      })
      .catch((error) => {
          console.log(error);
        })
      .then(() => {
        this.isLoadingTipoDoc = false
      })
    },

    getDataStepPago: function () {
      this.$validator.validateAll('register').then(result => {
        if (!result) return;

        this.isLoadingBtnStep = true

        var resp = null

        if (this.user !== false) {
          resp = this.ultimaFacturacion()
          .then((response) => {
            if (response.data) {
              this.datosPago.FactTipoCbteInput = response.data.facturacion_tipo_cbte
              this.datosPago.FactDocTipoInput = response.data.facturacion_doc_tipo_id
              this.datosPago.FactDocNroInput = response.data.facturacion_doc_numero
              this.datosPago.FactCategoriaIvaInput = response.data.facturacion_categoria_iva_id
              this.datosPago.FactRazonSocialInput = response.data.facturacion_razon_social
              this.datosPago.FactEmailInput = response.data.facturacion_email
              this.datosPago.FactTelefonoInput = response.data.facturacion_telefono
            } else {
              this.datosPago.FactEmailInput = this.user.email || ''
            }

            this.step = 3
          })
        } else {
          resp = this.validarEmail()
          .then(() => {
            // Copiar datos del registro
            this.datosPago.FactEmailInput = this.datos_registro.registro_email

            this.step = 3
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario', 'error')

                Object.keys(error.response.data).forEach(key => {
                  let value = error.response.data[key]

                  this.errors.add({
                    scope: 'register',
                    field: key,
                    msg: value
                  });
                });
                break;
              case 401:
                break;
              default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud')
            }
          })
        }

        resp.finally(() => {
          this.isLoadingBtnStep = false
        })
      });
    },

    validarEmail: function () {
      let data = {
        email: this.datos_registro.registro_email
      }

      return this.$http.post('validar/email', data)
    },

    validarDatosDePago: function () {
      this.$validator.validateAll('pago').then(result => {
        if (!result) return;

        this.isLoadingBtnStep = true

        this.datosPago.FactDocTipoText = this.$refs.FactDocTipoInput._data.selectedItems[0].descripcion
        this.datosPago.FactCategoriaIvaText = this.$refs.FactCategoriaIvaInput._data.selectedItems[0].descripcion

        this.isLoadingBtnStep = false
        this.step = 4
      });
    },

    confirmar: function () {
      this.isLoadingBtnStep = true

      let data = {
        cupones: this.selectedCupones,
        registro_email: this.datos_registro.registro_email,
        registro_password: this.datos_registro.registro_password,
        metodo_pago: this.datosPago.metodo_pago,
        facturacion_razon_social: this.datosPago.FactRazonSocialInput,
        facturacion_tipo_cbte: this.datosPago.FactTipoCbteInput,
        facturacion_doc_tipo_id: this.datosPago.FactDocTipoInput,
        facturacion_doc_numero: this.datosPago.FactDocNroInput,
        facturacion_categoria_iva_id : this.datosPago.FactCategoriaIvaInput,
        facturacion_email: this.datosPago.FactEmailInput,
        facturacion_telefono: this.datosPago.FactTelefonoInput,
        total: this.total,
        facturacion_doc_tipo_text: this.datosPago.FactDocTipoText,
        facturacion_categoria_iva_text: this.datosPago.FactCategoriaIvaText,
      }

      this.$http.post('confirmar', data)
      .then((response) => {
        if (response.data.token) {
          sessionStorage.setItem('cupones-token', response.data.token)
          this.$store.state.loggedIn = true
        }

        this.$router.push({
          name: 'compra-confirmada',
          params: {
            id: response.data.compra_id
          }
        })

        this.$eventHub.$emit('snackbar-message', 'Compra confirmada!')
      })
      .catch((error) => {
        switch (error.response.status) {
          case 400:
            this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario', 'error')

            Object.keys(error.response.data).forEach(key => {
              let value = error.response.data[key]

              this.errors.add({
                // TODO: Obtener scope de cada campo
                field: key,
                msg: value
              });
            });

            break;
          case 401:
            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud')
        }
      })
      .then(() => {
        this.isLoadingBtnStep = false
      })
    },

    onTipoComprobanteChange() {
      if (this.datosPago.FactDocNroInput) {
        return false;
      }

      var codigo = this.datosPago.FactTipoCbteInput;

      if (codigo === '001') {
        this.datosPago.FactDocTipoInput = '80';
      }
    }
  },

  mounted () {
    // Cargar datos
    this.query()
    this.listTiposComprobante()
    this.listCategoriasIVA()
    this.listMetodosPago()
    this.listTiposDocumento()
  }
}

</script>

<style scoped>
  .v-data-table-header-mobile {
    display: none;
  }

  .v-dialog--scrollable .v-subheader,
  .v-dialog--scrollable .v-list-item {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
</style>
